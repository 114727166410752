import { createTheme } from "@mui/material/styles";
export const ui_colors = {
  primary: "#437066",
  secondary: "#3CD0FF",
  primaryBack: "#F5F2F0",
  secondaryBack: "#F7F6F6",
  semiPrimary: "#f5f9ff",
  textPrimary: "#7F7F7F",
  lightGray: "#D9D9D9",
  pending: "#F7B217",
  error: "#ff0000",
};

export const border = (width = 1) => `${width}px solid #${ui_colors.lightGray}`;

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: `'IBM Plex Sans', 'Poppins', Inter`,
    },
  },

  palette: {
    primary: {
      main: ui_colors.primary,
    },
    secondary: {
      main: ui_colors.secondary,
    },
    error: {
      main: ui_colors.error,
    },
  },

  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: "pulse",
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          scale: "1.1",
          padding: "8px",
          textTransform: "capitalize",
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          color: "#343A40",
          fontFamily: "Poppins",
          fontSize: "56px",
          fontWeight: "500",
          lineHeight: "130%",
        },
        h2: {
          color: "#343A40",
          fontFamily: "Poppins",
          fontSize: "56px",
          fontWeight: "500",
          lineHeight: "130%",
          borderRadius: "12px",
          background: "#3CD0FF4D",
          width: "fit-content",
          display: "inline-block",
        },
        h3: {
          color: "#212529",
          fontFamily: "Poppins",
          fontSize: "44px",
          fontWeight: "500",
          lineHeight: "64px",
          width: "fit-content",
          display: "inline-block",
        },
        h4: {
          color: "#343A40",
          fontFamily: "Poppins",
          fontSize: "40px",
          fontWeight: "500",
          lineHeight: "130%",
        },
        h5: {
          color: "#343A40",
          fontFamily: "Poppins",
          fontSize: "40px",
          fontWeight: "500",
          lineHeight: "130%",
          borderRadius: "12px",
          background: "#3CD0FF4D",
          width: "fit-content",
          display: "inline-block",
        },
        h6: {
          color: "#212529",
          fontFamily: "Poppins",
          fontSize: "28px",
          fontWeight: "500",
          lineHeight: "44px",
          width: "fit-content",
          display: "inline-block",
        },
        body1: {
          color: "#495057",
          fontFamily: "IBM Plex Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          display: "inline-block",
        },
        body2: {
          color: "#495057",
          fontFamily: "IBM Plex Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          display: "inline-block",
        },
        body3: {
          color: "#0080FF",
          fontFamily: "IBM Plex Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          display: "inline-block",
        },
        body4: {
          color: "#6C757D",
          fontFamily: "IBM Plex Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          display: "inline-block",
        },
        body5: {
          color: "#495057",
          fontFamily: "IBM Plex Sans",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          display: "inline-block",
        },
        body6: {
          color: "#495057",
          fontFamily: "IBM Plex Sans",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
          display: "inline-block",
          padding: "10px 15px",
          borderRadius: "5px",
          transition: "all 0.2s ease-in-out",
          borderRadius: "20px",
          textAlign: "center",
          // boxShadow:
          //   "0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
          ":hover": {
            color: "#ffffff",
            // textDecoration: "underline",
            cursor: "pointer",
            backgroundColor: "#437066",
            borderRadius: "20px",
          },

          ":active": {
            color: "#0080FF",
            textDecoration: "underline",
            cursor: "default",
            backgroundColor: "#e5e5e5",
          },
        },
        body7: {
          color: "#495057",
          fontFamily: "IBM Plex Sans",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          display: "inline-block",
        },
        body8: {
          color: "#495057",
          fontFamily: "IBM Plex Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          display: "inline-block",
        },
        body9: {
          color: "#0080FF",
          fontFamily: "IBM Plex Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          display: "inline-block",
        },
        body10: {
          color: "#6C757D",
          fontFamily: "IBM Plex Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          display: "inline-block",
        },
        body11: {
          color: "#495057",
          fontFamily: "IBM Plex Sans",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          display: "inline-block",
        },
        body12: {
          color: "#437066",
          fontFamily: "IBM Plex Sans",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
          display: "inline-block",
          padding: "10px 15px",
          borderRadius: "5px",
          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.2)",
          background: "#ffffff",
          borderRadius: "20px",
          textAlign: "center",
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          width: "100%",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          justifyContent: "space-between",
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        MenuProps: {
          sx: {
            background: "#000",
            ".MuiMenu-paper": {
              borderRadius: 8,
              border: border(),
            },
          },
        },
      },
      styleOverrides: {
        outlined: {
          "&.MuiOutlinedInput-input": {
            padding: "8px 12px",
          },
          "&.MuiPaper-root": {
            borderRadius: 8,
            border: border(),
            // background: "#b94040",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          color: "#FFF",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "130%",
          letterSpacing: "0.03px",
          display: "inline-flex",
          padding: "4px 4px 4px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "12px",
          textDecoration: "capitalize",
          borderRadius: "12px",
          transition: "all 200ms !important",
          boxShadow: "none",
          height: 48,
          width: "fit-content",
          "&.Mui-disabled": {
            background: "transparent",
            boxShadow: `inset 0px 0px 0px 1px ${ui_colors.primary}`,
          },
        },

        outlined: {
          color: ui_colors.primary,
          borderColor: ui_colors.primary,
          ":hover": {
            boxShadow: "none",
          },
        },
        dark: {
          background: "#3B475D",
          color: "white",
          fontSize: "14px",
          padding: "4px 8px 4px 8px",
          ":hover": {
            background: "#2c3649",
            color: "white",
            padding: "4px 8px 4px 8px",
            boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)`,
          },
        },
        mobile: {
          color: "#FFF",
          background: "#437066",
          fontSize: "14px",
          textAlign: "center",
          fontFamily: "IBM Plex Sans",
          fontWeight: "400",
          lineHeight: "130%",
          letterSpacing: "0.03px",
          display: "inline-flex",
          padding: "4px 4px 4px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "12px",
          textDecoration: "capitalize",
          borderRadius: "12px",
          transition: "all 200ms !important",
          boxShadow: "none",
          height: 48,
          width: "fit-content",
        },
        products: {
          color: "#1E1E1E",
          fontSize: "14px",
          padding: "4px 8px 4px 8px",
          ":hover": {
            boxShadow: "none",
            background: "transparent",
          },
        },
        outlined_red: {
          color: ui_colors.error,
          background: "#fff",
          boxShadow: "inset 0px 0px 0px 1px #ff0000",
          ":hover": {
            boxShadow: "inset 0px 0px 0px 1px #ff0000",
          },
        },
        text: {
          boxShadow: "none",
        },
      },
    },
  },
});
