import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  MenuItem,
  Menu,
  Stack,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import MeetingModal from "./MeetingModal";
import ReorderIcon from "@mui/icons-material/Reorder";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showMeeting, setShowMeeting] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleClose();
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <AppBar
      position="fixed"
      elevation={scrolled ? 1 : 0}
      style={{
        justifyContent: "center",
        backgroundColor: scrolled ? "#fff" : "#d9fcf5",
        minHeight: "76px",
        paddingRight: isSmallScreen ? "20px" : "46px",
        paddingLeft: isSmallScreen ? "20px" : "46px",
      }}
    >
      <Toolbar>
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
        >
          <Box
            minWidth={"50%"}
            display="flex"
            flexGrow={1}
            onClick={() => navigate("/")}
          >
            <img
              src="Bwell_logo.svg"
              alt="Niroggyan logo"
              style={{
                cursor: "pointer",
                width: "190px",
                height: "64px",
              }}
            />
          </Box>
          <Stack
            minWidth={"50%"}
            direction={"row"}
            justifyContent={isSmallScreen ? "flex-end" : "space-between"}
          >
            <div>
              {isSmallScreen && (
                <Button
                  style={{ direction: "rtl" }}
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  variant="products"
                >
                  <ReorderIcon />
                </Button>
              )}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={() => handleMenuItemClick("/smartReports")}>
                  Smart Reports
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("/vizapp")}>
                  Web App
                </MenuItem>
                {/* <MenuItem onClick={() => handleMenuItemClick('/analytics')}>Analytics</MenuItem> */}
                <MenuItem onClick={() => handleMenuItemClick("/personal")}>
                  Health Tools
                </MenuItem>
              </Menu>
            </div>

            {!isSmallScreen && (
              <Stack gap={"16px"} direction={"row"}>
                <Typography
                  variant={pathname === "/smartReports" ? "body12" : "body6"}
                  style={{}}
                  onClick={() => handleMenuItemClick("/smartReports")}
                >
                  SMART REPORTS
                </Typography>
                <Typography
                  variant={pathname === "/vizapp" ? "body12" : "body6"}
                  style={{}}
                  onClick={() => handleMenuItemClick("/vizapp")}
                >
                  WEB APP
                </Typography>
                {/* <Typography variant={"body6"} style={{}} onClick={() => handleMenuItemClick('/analytics')}>Analytics</Typography> */}
                <Typography
                  variant={pathname === "/personal" ? "body12" : "body6"}
                  style={{}}
                  onClick={() => handleMenuItemClick("/personal")}
                >
                  HEALTH TOOLS
                </Typography>
              </Stack>
            )}
            <MeetingModal
              showMeeting={showMeeting}
              setShowMeeting={setShowMeeting}
            />
          </Stack>
        </Stack>
      </Toolbar>
      {/* Breadcrumbs components */}
    </AppBar>
  );
};

export default Navbar;
