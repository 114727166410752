import { Button, useMediaQuery } from "@mui/material";
import React from "react";
import pdfBase64Collection from "../allPdfsBase64";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTheme } from "@emotion/react";
import Swal from "sweetalert2";
import useGAEventsTracker from "./GAEventsHook";

export const DownloadRedesigned = ({ sample }) => {
  const GAEventsTracker = useGAEventsTracker("Download Activity");
  GAEventsTracker(sample, "downloaded");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  let downloadablePdf = "";
  let pdfTitle = "";

  if (
    sample === "Dynamic Smart Report" ||
    sample === "गतिशील स्मार्ट रिपोर्ट"
  ) {
    pdfTitle = "Sample_Personal_Report";
    downloadablePdf = pdfBase64Collection[0]["pdfBase64"]["digital"];
  } else if (
    sample === "Advanced Smart Report" ||
    sample === "अग्रिम स्मार्ट रिपोर्ट"
  ) {
    pdfTitle = "Sample_Total_Report";
    downloadablePdf = pdfBase64Collection[1]["pdfBase64"];
  } else if (
    sample === "Compact Smart Report" ||
    sample === "कॉम्पैक्ट स्मार्ट रिपोर्ट"
  ) {
    pdfTitle = "Sample_Compact_Report";
    downloadablePdf = pdfBase64Collection[2]["pdfBase64"]["digital"];
  } else if (sample === "Body Summary" || sample === "मानव शरीर सारांश") {
    pdfTitle = "Body Summary";
    downloadablePdf = pdfBase64Collection[3].pdfBase64;
  } else if (sample === "Smart Summary Sample" || sample === "स्मार्ट सारांश") {
    pdfTitle = "Smart Summary Report";
    downloadablePdf = pdfBase64Collection[4].pdfBase64;
  } else if (sample === "Vernacular Language" || sample === "स्थानीय भाषा") {
    pdfTitle = "Vernacular Report";
    downloadablePdf = pdfBase64Collection[5].pdfBase64;
  } else if (sample === "Historical" || sample === "ऐतिहासिक विश्लेषण") {
    pdfTitle = "Sample_Historical_Report";
    downloadablePdf = pdfBase64Collection[6].pdfBase64;
  } else if (
    sample === "Test Recommender" ||
    sample === "कोविड रिपोर्ट (+/-)"
  ) {
    pdfTitle = "Test Recommender";
    downloadablePdf = pdfBase64Collection[7].pdfBase64;
  } else if (
    sample === "Corporate Dashboard" ||
    sample === "कॉर्पोरेट डैशबोर्ड"
  ) {
    pdfTitle = "Corporate Dashboard";
    downloadablePdf = pdfBase64Collection[8].pdfBase64;
  } else if (sample === "Digi App" || sample === "डिजी ऐप") {
    pdfTitle = "Digi App";
    downloadablePdf = pdfBase64Collection[9].pdfBase64;
  } else if (sample === "Health Risk Calculator") {
    pdfTitle = "Health Risk Report";
    downloadablePdf = pdfBase64Collection[10].pdfBase64;
  } else if (sample === "Chat Bot") {
    pdfTitle = "NirogGyan Chat Bot";
    downloadablePdf = pdfBase64Collection[11].pdfBase64;
  } else if (sample === "Cover Page") {
    pdfTitle = "Cover Page";
    downloadablePdf = pdfBase64Collection[12].pdfBase64;
    // downloadablePdf = pdfBase64Collection[12].pdfBase64
  } else if (sample === "Language Options") {
    pdfTitle = "Language Options";
    downloadablePdf = pdfBase64Collection[13].pdfBase64;
    // downloadablePdf = pdfBase64Collection[12].pdfBase64
  } else if (sample === "Fitness Report") {
    pdfTitle = "SampleFitnessReport";
    downloadablePdf = pdfBase64Collection[14].pdfBase64;
  }

  const downloadPDF = (pdfName, pdfBase64) => {
    console.log("the file getting downloaded");
    const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    const downloadLink = document.createElement("a");
    let fileName = "";
    if (
      pdfName === "Sample_Personal_Report" ||
      pdfName === "Sample_Compact_Report" ||
      pdfName === "Sample_Total_Report"
    ) {
      fileName = `${pdfName + "_" + ("digital" ? "digital" : "")}.pdf`;
    } else {
      fileName = `${pdfName}.pdf`;
    }

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    Swal.fire(
      "File downloaded!",
      "Your file has been downloaded successfully.",
      "success"
    );
  };

  return (
    <Button
      variant={isSmallScreen ? "mobile" : "contained"}
      id="iconMarginsCss"
      onClick={() => {
        Swal.fire({
          title: "Downloading file...",
          allowOutsideClick: false,
          didOpen: () => {
            downloadPDF(pdfTitle, downloadablePdf);
          },
        });
      }}
      className="downloadButton"
    >
      Download Sample
      <ArrowDownwardIcon
        sx={{
          borderRadius: "12px",
          background: "white",
          fill: "#437066",
          width: "44px",
          height: "44px",
        }}
      />
    </Button>
  );
};
