import {
  Box,
  Button,
  Paper,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ProductCarousel from "./ProductCarouselComponent";
import MeetingModal from "./MeetingModal";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import PagesIcon from "@mui/icons-material/Pages";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import AddOnsComponent from "./AddOns";
import Slider from "react-slick";
import "../css/customSlider.css";
import useGAEventsTracker from "./GAEventsHook";

const slides = [
  {
    title: "Personal",
    heading: "Personal",
    description:
      "Personalised reports highlighting the patient’s problematic parameters in great detail. The latest Smart Report to make waves in the industry.",
    illustration: "report1.png",
    audio:
      "https://dn96iqv3kf32j.cloudfront.net/brochure-images/audios/dynamicSRaudio.mp3",
    sample: "Dynamic Smart Report",
    images: [
      {
        src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-files/PersonalReportBwell.jpg",
        alt: "Personal Image 1",
      },
      // {
      //   src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-files/new-landing-page-resources/dynamic/digital/dynamic-digital-3.png",
      //   alt: "Personal Image 2",
      // },
    ],
  },
  {
    title: "Total",
    heading: "Total",
    description:
      "Comprehensive reports for those who want to know the most about their test results, with deep-dive into all the parameters. Perfect for health-conscious individuals.",
    illustration: "report2.png",
    audio:
      "https://dn96iqv3kf32j.cloudfront.net/brochure-images/audios/advanceSRaudio.mp3",
    sample: "Advanced Smart Report",
    images: [
      {
        src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-files/TotalBwel.png",
        alt: "Total Image 1",
      },
      // {
      //   src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/advanced-section/Advanced-Report-0002.jpg",
      //   alt: "Total Image 2",
      // },
      // {
      //   src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/advanced-section/Advanced-Report-0003.jpg",
      //   alt: "Total Image 3",
      // },
      // {
      //   src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/advanced-section/Advanced-Report-0004.jpg",
      //   alt: "Total Image 4",
      // },
      // {
      //   src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/advanced-section/Advanced-Report-0005.jpg",
      //   alt: "Total Image 5",
      // },
      // {
      //   src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/advanced-section/Advanced-Report-0006.jpg",
      //   alt: "Total Image 6",
      // },
      // {
      //   src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/advanced-section/Advanced-Report-0007.jpg",
      //   alt: "Total Image 7",
      // },
    ],
  },
  {
    title: "Compact",
    heading: "Compact",
    description:
      "Simple reports for those who want to quickly check their results with compact graphs, minimal text and lesser pages. Great for doctors and acute patients.",
    illustration: "report4.png",
    audio:
      "https://dn96iqv3kf32j.cloudfront.net/brochure-images/audios/compactSRaudio",
    sample: "Compact Smart Report",
    images: [
      {
        src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-files/CompactBwell.jpg",
        alt: "Compact Image 1",
      },
      // {
      //   src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-files/new-landing-page-resources/compact/digital/compact-digital-3.png",
      //   alt: "Compact Image 2",
      // },
    ],
  },
  {
    title: "Trends",
    heading: "Trends",
    description:
      "Present & historical test results explained with easy-to-understand graphs and dynamic information. Creates maximum value to catch problematic trends of patients.",
    illustration: "report3.png",
    audio:
      "https://dn96iqv3kf32j.cloudfront.net/brochure-images/audios/historicalSRaudio.mp3",
    sample: "Historical",
    images: [
      {
        // src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-files/new-landing-page-resources/new-historical.jpg",
        src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-files/HistoricalBwell.jpg",
        alt: "Trends Image 1",
      },
      // {
      //   // src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/updated-page1-historical.jpg",
      //   src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-images/brochureHistorical1-page2.jpg",
      //   alt: "Trends Image 2",
      // },
      // {
      //   // src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/updated-page2-historical.jpg",
      //   src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-images/brochureHistorical1-page3.jpg",
      //   alt: "Trends Image 3",
      // },
      // {
      //   // src: "https://dn96iqv3kf32j.cloudfront.net/brochure-images/updated-page3-historical.jpg",
      //   src: "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-images/brochureHistorical1-page4.jpg",
      //   alt: "Trends Image 4",
      // },
    ],
  },
];
const addOns = [
  {
    title: "Body Summary",
    heading:
      "Your Smart Report summarised at the very beginning, with focus on out-of-range parameters. Available in 3 versions - human body, small table, big table.",
    picture: "humanbodysummary.jpg",
  },
  {
    title: "Cover Page",
    heading:
      "Add that extra aesthetic zing to your Smart Reports with your very own Cover Page, that suits your brand and makes patients feel excited to read their reports.",
    picture: "coverPage.png",
  },
  // {
  //   title: "Test Recommender",
  //   heading:
  //     "Scientific algorithm-based additional test recommendation engine, which notifies patients on what else to get tested for to get the complete picture of their health.",
  //   picture: "testrecommendation.jpg",
  // },
];
export default function SmartReportPage() {
  const GAEventsTracker = useGAEventsTracker("Smart Reports Activity");
  const [showMeeting, setShowMeeting] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const settings = {
    dots: true,
    // arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: false,
    // speed: 14000,
    // autoplaySpeed: 500,
    // cssEase: "linear",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          width: "100%",
        },
      }}
    >
      <Paper elevation={0}>
        <Stack
          style={{
            background: "#d9fcf5",
            alignItems: "center",
            height: isSmallScreen ? "auto" : "100vh",
          }}
          paddingX={isSmallScreen ? "20px" : "60px"}
          marginBottom={"60px"}
          paddingTop={"120px"}
          direction={isSmallScreen ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={isSmallScreen ? "flex-start" : "flex-start"}
          gap={"48px"}
        >
          <Stack width={isSmallScreen ? "100%" : "60%"} gap={"48px"}>
            <Typography variant={isSmallScreen ? "h6" : "h3"}>
              Smart Reports
            </Typography>
            <Stack width={"90%"} gap={"10px"}>
              <Typography variant={isSmallScreen ? "body7" : "body1"}>
                Smart Health Reports simplify medical test results with clear
                explanations, visualizations, and actionable insights
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                setShowMeeting(true);
                GAEventsTracker("Demo Booked", "successfully");
              }}
              variant={isSmallScreen ? "mobile" : "contained"}
            >
              Book Demo
              <ArrowForwardIcon
                sx={{
                  borderRadius: "12px",
                  background: "white",
                  fill: "#437066",
                  width: "44px",
                  height: "44px",
                }}
              />
            </Button>
            <Stack direction={"row"} gap={"16px"}>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  // ":hover": {
                  //   background: "#6c757d30",
                  // },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <AutoFixHighIcon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#437066",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  Print Versions{" "}
                </Typography>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  // ":hover": {
                  //   background: "#6c757d30",
                  // },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <ColorLensIcon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#437066",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  Brand Colors{" "}
                </Typography>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  // ":hover": {
                  //   background: "#6c757d30",
                  // },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <PagesIcon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#437066",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  Multiple Templates{" "}
                </Typography>
              </Paper>
            </Stack>
            <MeetingModal
              showMeeting={showMeeting}
              setShowMeeting={setShowMeeting}
            />
          </Stack>
          <Stack width={isSmallScreen ? "40vh" : "35%"}>
            <img
              style={{
                width: isSmallScreen ? "auto" : "100%",
                height: isSmallScreen ? "auto" : "100%",
              }}
              src="Smart_report.png"
              alt="Smart Report Page"
            />
          </Stack>
        </Stack>
        <ProductCarousel slides={slides} />
        <Typography
          paddingX={isSmallScreen ? "20px" : "60px"}
          marginTop={isSmallScreen ? "30px" : ""}
          variant={isSmallScreen ? "h6" : "h3"}
        >
          Smart Report Add-Ons
        </Typography>
        {/* {addOns.map((product, index) => (
          <AddOnsComponent
            key={index}
            title={product.title}
            heading={product.heading}
            page={product.page}
            picture={product.picture}
          />
        ))} */}
        <Box>
          <Slider {...settings}>
            {addOns.map((product, index) => (
              <AddOnsComponent
                key={index}
                title={product.title}
                heading={product.heading}
                page={product.page}
                picture={product.picture}
              />
            ))}
          </Slider>
        </Box>
      </Paper>
    </Box>
  );
}
