import React from "react";
import { InlineWidget } from "react-calendly";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function MeetingModal({ showMeeting, setShowMeeting }) {
  const handleCloseMeeting = () => setShowMeeting(false);

  return (
    <Dialog open={showMeeting} onClose={handleCloseMeeting}>
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseMeeting();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <InlineWidget
          styles={{
            marginTop: "30px",
            height: "400px",
          }}
          url="https://calendly.com/tom-uy9n/30min"
        />
      </DialogContent>
    </Dialog>
  );
}
